import { Button, TextField } from "@mui/material";
import { default as MuiModal } from "@mui/material/Modal";
import Box from "@mui/system/Box";
import { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { useNotifications } from "../../../components/Notification";

export default function Modal(props: ModalProps) {
  const { showNotification } = useNotifications();
  const [reply, setReply] = useState(props.reply);
  const [replyReview] = useMutation(REPLY_REVIEW_MUTATION);
  const handleSubmit = async () => {
    if (!reply || reply.trim() === "") {
      showNotification({
        message: "内容を入力してください",
        severity: "error",
      });
      return;
    }
    await replyReview({
      variables: { reviewId: props.reviewId, reply },
    });
    await props.setValue(false);
    await props.refetch();
    showNotification({
      message: "編集内容が保存されました",
      severity: "success",
    });
  };
  return (
    <MuiModal
      open={props.value}
      onClose={() => props.setValue(false)}
      disableAutoFocus={true}
      BackdropProps={{
        sx: {
          backgroundColor: "rgba(0, 0, 0, 0.1)",
        },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          backgroundColor: "rgb(255, 255, 255, 1)",
          borderRadius: "5px",
          border: "1px solid black",
          p: 4,
          textAlign: "center",
        }}
      >
        {props.value}
        <Box component="section" sx={{ textAlign: "left", paddingBottom: 2 }}>
          <p></p>
          <span
            dangerouslySetInnerHTML={{
              __html:
                "ご返信内容をご記入お願いします。<br/> ※ご返信内容はWebサイトに表示され、他一般ユーザーも閲覧可能です。",
            }}
          />
        </Box>
        <TextField
          className="login-field"
          variant="outlined"
          value={reply}
          name="onHoldReason"
          fullWidth
          multiline
          rows={4}
          placeholder="ここに対応状況を入力出来るようにする
※何度でも入力・修正可能"
          onChange={(event: { target: { value: string } }) =>
            setReply(event.target.value)
          }
        />
        <Box
          component="section"
          sx={{ display: "flex", justifyContent: "end", paddingTop: 2 }}
        >
          <Button
            style={{ backgroundColor: "#c8a063", color: "#ffffff" }}
            type="submit"
            variant="contained"
            onClick={handleSubmit}
          >
            保存
          </Button>
        </Box>
      </Box>
    </MuiModal>
  );
}

const REPLY_REVIEW_MUTATION = gql`
  mutation EditBookingStatusMutation($reviewId: ID!, $reply: String!) {
    editReplyReview(input: { reviewId: $reviewId, reply: $reply }) {
      success
    }
  }
`;

interface ModalProps {
  reply: string | "";
  reviewId: string;
  value: boolean;
  setValue: (value: boolean) => void;
  refetch: () => void;
}
