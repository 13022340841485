import { IconButton, Popover, Tooltip, Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useContext, useState, useRef } from "react";
import IconPicker from "./IconPicker";
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { useParams } from "react-router-dom";

import "./InclusionCard.css";
import { BasicInfoField } from "../types";
import ControlledTextField from "./ControlledTextField";
import { inclusionIcons } from "./inclusionIcons";
import { RevertableArrayItem } from "../../components/Revertable";
import { FieldState } from "../../types";
import { FormContext } from "../../components/FormContext";
import {
  getArrayFieldValue,
  getArrayItemState,
  setArrayFieldValue,
} from "../../formValues";
import useUploadImage from "../../hooks/useImageUploader";
import PlanImageDialog from "../../PlanImageDialog";

export default function InclusionCard({
  arrayName,
  itemIndex,
  formIdentifier,
  setDeleted,
  remove,
}: InclusionCardProps) {
  const params = useParams();
  const inputRef = useRef<HTMLInputElement>(null);
  const { uploading, uploadImage } = useUploadImage();
  const { form } = useContext(FormContext);
  const [isOpenImagesModal, setIsOpenImagesModal] = useState(false);

  const setIcon = (newIconName: string) =>
    setArrayFieldValue({
      form,
      arrayName,
      itemIndex,
      fieldName: BasicInfoField.InclusionIconName,
      value: newIconName,
    });

  const setImageUrl = (newImage: string | null) =>
    setArrayFieldValue({
      form,
      arrayName,
      itemIndex,
      fieldName: BasicInfoField.InclusionImageUrl,
      value: newImage,
    });

  const isDeleted =
    getArrayFieldValue({
      form,
      arrayName,
      itemIndex,
      fieldName: BasicInfoField.InclusionIsDeleted,
    }) ?? false;

  const isAdded =
    getArrayItemState({ form, arrayName, itemIndex }) === FieldState.added;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openIconPicker, setOpenIconPicker] = useState(false);

  const onClickIcon = (e: React.MouseEvent<HTMLElement>) => {
    setOpenIconPicker(true);
    setAnchorEl(e.currentTarget);
  };

  const iconName = getArrayFieldValue({
    form,
    arrayName,
    itemIndex,
    fieldName: BasicInfoField.InclusionIconName,
  });

  const imageUrl = getArrayFieldValue({
    form,
    arrayName,
    itemIndex,
    fieldName: BasicInfoField.InclusionImageUrl,
  });

  return (
    <>
      <PlanImageDialog
        open={isOpenImagesModal}
        handleClose={() => setIsOpenImagesModal(false)}
        imageUrl={imageUrl}
        onConfirm={(imageUrl: string | null) => {
          setImageUrl(imageUrl);
        }}
        planId={params.id!}
      />
      <RevertableArrayItem
        arrayName={arrayName}
        itemIndex={itemIndex}
        remove={remove}
      >
        <input
          type="file"
          style={{ display: "none" }}
          ref={inputRef}
          onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
            const result = await uploadImage(
              e.target.files![0],
              `plans/${params.id}/inclusions`
            );
            if (result) {
              setImageUrl(result.mediaUrl);
            }
          }}
        />
        <div
          className={`InclusionCard ${getArrayItemState({
            form,
            arrayName,
            itemIndex,
          })}`}
        >
          {imageUrl ? (
            <div className="ImageUrl">
              <ClearRoundedIcon onClick={() => setImageUrl(null)} />
              <img src={imageUrl} alt="image url" />
            </div>
          ) : (
            <IconButton onClick={onClickIcon} disabled={isDeleted}>
              {inclusionIcons.get(iconName)}
            </IconButton>
          )}
          <Popover
            anchorEl={anchorEl}
            open={openIconPicker}
            onClose={() => setOpenIconPicker(false)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <IconPicker
              handleClick={(newIconName: string) => {
                setIcon(newIconName);
                setOpenIconPicker(false);
              }}
            />
          </Popover>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              gap: "0.65rem",
            }}
          >
            <ControlledTextField
              control={form.control}
              name={`${formIdentifier}.${BasicInfoField.InclusionTitle}`}
              label={"タイトル"}
              fullWidth
              minRows={1}
              maxRows={2}
              multiline
              disabled={isDeleted}
            />
            <ControlledTextField
              control={form.control}
              name={`${formIdentifier}.${BasicInfoField.InclusionDescription}`}
              label={"説明文"}
              minRows={1}
              maxRows={2}
              multiline
              fullWidth
              disabled={isDeleted}
            />
            <div className="button">
              <Button
                variant="contained"
                onClick={() => setIsOpenImagesModal(true)}
              >
                画像を選択
              </Button>
              <Button
                disabled={uploading}
                variant="contained"
                onClick={() => inputRef.current?.click()}
              >
                画像をアップロード
              </Button>
            </div>
          </div>

          {isDeleted ? (
            <Tooltip title="undelete">
              <span>
                <IconButton onClick={() => setDeleted(false)}>
                  <RestoreFromTrashIcon />
                </IconButton>
              </span>
            </Tooltip>
          ) : (
            <Tooltip title="delete">
              <span>
                <IconButton onClick={() => setDeleted(true)} disabled={isAdded}>
                  <DeleteIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
        </div>
      </RevertableArrayItem>
    </>
  );
}

interface InclusionCardProps {
  arrayName: string;
  formIdentifier: string;
  itemIndex: number;
  setDeleted: (isDeleted: boolean) => void;
  remove: () => void;
}
