import Stack from "@mui/material/Stack";
import InclusionCard from "./InclusionCard";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Chip } from "@mui/material";
import { BasicInfoField } from "../types";
import { useFieldArray } from "react-hook-form";
import { v4 as uuidV4 } from "uuid";
import { useContext } from "react";
import { FormContext } from "../../components/FormContext";
import {
  getArrayFieldValue,
  setArrayFieldValue,
  setArrayItemValue,
} from "../../formValues";

export default function InclusionList({
  arrayName,
  category,
}: InclusionListProps) {
  const { form } = useContext(FormContext);

  const { fields, append, remove } = useFieldArray({
    name: arrayName,
    control: form.control,
  });

  const removeItem = (itemIndex: number) => {
    form.addRemovedItem({
      fieldName: arrayName,
      fieldId: getArrayFieldValue({
        form,
        arrayName,
        itemIndex,
        fieldName: BasicInfoField.InclusionId,
      }),
    });
    remove(itemIndex);
  };

  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Chip
          label={category}
          icon={<AddCircleIcon />}
          clickable
          onClick={() => {
            append({});
            const id = uuidV4();
            setArrayItemValue({
              form,
              arrayName,
              itemIndex: fields.length,
              value: {
                [BasicInfoField.InclusionTitle]: "",
                [BasicInfoField.InclusionDescription]: "",
                [BasicInfoField.InclusionImageUrl]: "",
                [BasicInfoField.InclusionIconName]: "GoPrimitiveDot",
                [BasicInfoField.InclusionId]: id,
                [BasicInfoField.InclusionIsDeleted]: false,
                [BasicInfoField.InclusionCategory]: category,
              },
            });
          }}
        />
      </div>
      <Stack>
        <ul>
          {fields.map((field, itemIndex) => {
            return (
              <InclusionCard
                key={field.id}
                arrayName={arrayName}
                itemIndex={itemIndex}
                formIdentifier={`${arrayName}.${itemIndex}`}
                setDeleted={(isDeleted: boolean) =>
                  setArrayFieldValue({
                    form,
                    arrayName,
                    itemIndex,
                    fieldName: BasicInfoField.InclusionIsDeleted,
                    value: isDeleted,
                  })
                }
                remove={() => removeItem(itemIndex)}
              />
            );
          })}
        </ul>
      </Stack>
    </>
  );
}

interface InclusionListProps {
  arrayName: string;
  category: string;
}
