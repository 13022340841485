export enum BasicInfoField {
  Name = "name",
  Subtitle = "subtitle",
  Description = "description",
  Remarks = "remarks",
  ExperienceInclusions = "experienceInclusions",
  NotIncludedInclusions = "notIncludedInclusions",
  MealInclusions = "mealInclusions",
  GiftInclusions = "giftInclusions",
  SeatInclusions = "seatInclusions",
  InclusionId = "fieldId",
  InclusionTitle = "title",
  InclusionDescription = "description",
  InclusionImageUrl = "imageUrl",
  InclusionCategory = "category",
  InclusionIconName = "iconName",
  InclusionIsDeleted = "isDeleted",
}

export const inclusionCategory = new Map([
  [BasicInfoField.MealInclusions, "お食事"],
  [BasicInfoField.GiftInclusions, "特典ギフト"],
  [BasicInfoField.ExperienceInclusions, "体験・移動"],
  [BasicInfoField.SeatInclusions, "お席"],
  [BasicInfoField.NotIncludedInclusions, "含まれないもの"],
]);
