export const ALL_VENUES = "all";

export type Reservation = {
  id: string;
  status: "NOT_YET_RESERVED" | "RESERVED" | "REJECTED" | "CANCELLED";
  datetime: string;
  createdAt: string;
  cancelledAt: string;
  commissionRate: number;
  venuePaymentRate: number;
  cancellationRate: number;
  activity: {
    id: string;
    name?: string;
    commissionRate: number;
    onSiteCommissionRate: number;
    plan: {
      id: string;
      name: string;
    };
    venue: {
      id: string;
      name: string;
    };
  };
  booking: {
    id: string;
    familyName: string;
    familyNameFurigana: string;
    givenName: string;
    givenNameFurigana: string;
    status: string;
    paymentStatus: "NOT_YET_PAID" | "PAID" | "REFUNDED";
    paymentAmount: number;
    paymentCurrency: string;
    noShow: boolean;
    planSnapshot: {
      id: string;
      name: string;
      activities: {
        id: string;
        name?: string;
      }[];
    };
    payment: {
      id: string;
      bookingId: string;
      method: "STRIPE" | "AMAZON_PAY" | "ON_SITE_PAYMENT";
    };
    productOrders: ProductOrder[];
  };
  items: {
    id: string;
    costsCurrencyCode: string;
    quantity: number;
    priceType: {
      name: string;
      unitType: string;
      amountInMinorUnits: number;
      amount: number;
      contractedPriceInMinorUnits: number;
      contractedPrice: number;
      currencyCode: string;
    };
  }[];
  costs: {
    commissionRate: number;
    onSiteCommissionRate: number;
    venuePaymentRate: number;
    contractedAmount: number;
    commission: number;
    onSiteCommission: number;
    venuePaymentAmount: number;
    currencyCode: string;
    cancellationFee: number;
    productOrderCost: number;
    productOrderCommissionRate: number;
    productOrderCommissionFee: number;
    isNotBagFee: number;
  };
};

export interface RenderParams {
  row: Reservation;
}

export interface ProductOrder {
  id: string;
  productId: string;
  bookingId: string;
  quantity: number;
  status: ProductOrderStatus;
  trackingNumber: string;
  giftmallOrderId: string;
  product: Product;
  price?: number;
}

export enum ProductOrderStatus {
  CREATED = "CREATED",
  REQUESTED = "REQUESTED",
  CONFIRMED = "CONFIRMED",
  SHIPPED = "SHIPPED",
  FAILED = "FAILED",
}

export interface Product {
  id: string;
  templateId: string;
  stock: number;
  minimumDeliveryDays: number;
  name: string;
  overrideName: string;
  sellingPrice: number;
  template: Template;
}

export interface Template {
  id: string;
  name: string;
  displayName: string;
  overrideDisplayName: string;
  giftmallUrl: string;
  description?: string;
  withPaperbag: boolean;
  commissionRate: number;
}
