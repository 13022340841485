import { Plan, PlanJson } from "../../List";

import InclusionList from "./components/InclusionList";
import ControlledTextField from "./components/ControlledTextField";
import { BasicInfoField, inclusionCategory } from "./types";

import "./BasicInfo.css";
import { Revertable } from "../components/Revertable";
import { useContext } from "react";
import { FormContext } from "../components/FormContext";
import { getFieldState } from "../formValues";

function BasicInfoEditorForm() {
  const { form } = useContext(FormContext);

  return (
    <div className="BasicInfo">
      <div style={{ display: "flex", flexDirection: "column", gap: "0.65rem" }}>
        <Revertable fieldName={BasicInfoField.Name}>
          <ControlledTextField
            name={BasicInfoField.Name}
            label="販売プラン名"
            control={form.control}
            fullWidth
            multiline
            maxRows={4}
            className={getFieldState({
              form,
              fieldName: BasicInfoField.Name,
            })}
          />
        </Revertable>

        <Revertable fieldName={BasicInfoField.Subtitle}>
          <ControlledTextField
            name={BasicInfoField.Subtitle}
            label="サブタイトル"
            control={form.control}
            fullWidth
            multiline
            maxRows={4}
            minLength={1}
            className={getFieldState({
              form,
              fieldName: BasicInfoField.Subtitle,
            })}
          />
        </Revertable>

        <Revertable fieldName={BasicInfoField.Description}>
          <ControlledTextField
            name={BasicInfoField.Description}
            label="説明文"
            control={form.control}
            fullWidth
            multiline
            minRows={4}
            maxRows={10}
            minLength={100}
            maxLength={1000}
            className={getFieldState({
              form,
              fieldName: BasicInfoField.Description,
            })}
          />
        </Revertable>

        <Revertable fieldName={BasicInfoField.Remarks}>
          <ControlledTextField
            name={BasicInfoField.Remarks}
            label="留意点"
            control={form.control}
            fullWidth
            multiline
            minLength={2}
            maxLength={600}
            minRows={6}
            maxRows={10}
            className={getFieldState({
              form,
              fieldName: BasicInfoField.Remarks,
            })}
          />
        </Revertable>
      </div>
      <div className="InclusionCards">
        {[...inclusionCategory.entries()].map(([arrayName, category], i) => {
          return (
            <InclusionList key={i} arrayName={arrayName} category={category} />
          );
        })}
      </div>
    </div>
  );
}

export type Inclusion = PlanJson["inclusions"][0];
export type Item = Inclusion["items"][0];

function getDefaultValuesBasicInfo(plan: Plan) {
  const basicDefaults = {
    [BasicInfoField.Name]: plan.publishedPlan.json.name,
    [BasicInfoField.Subtitle]: plan.publishedPlan.json.subtitle,
    [BasicInfoField.Description]: plan.publishedPlan.json.description,
    [BasicInfoField.Remarks]: plan.publishedPlan.json.remarks,
  };

  // find inclusions per category and add a unique id per item
  const inclusionDefaults = [...inclusionCategory.entries()].reduce(
    (obj, [fieldName, category]) => {
      return Object.assign({}, obj, {
        [fieldName]:
          plan.inclusions
            .find((inclusion) => inclusion.category === category)
            ?.items.map((item) => {
              return {
                ...item,
                [BasicInfoField.InclusionIsDeleted]: false,
                [BasicInfoField.InclusionId]: item.id,
              };
            }) ?? [],
      });
    },
    {}
  );

  return { ...basicDefaults, ...inclusionDefaults };
}

const basicInfoExport = {
  form: BasicInfoEditorForm,
  getDefaultValues: getDefaultValuesBasicInfo,
};
export default basicInfoExport;
