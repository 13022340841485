const reservationStatusTranslations = {
  NOT_YET_RESERVED: "要回答",
  RESERVED: "確定",
  REJECTED: "受入不可",
  CANCELLED: "キャンセル",
};

export const bookingStatusTranslations = {
  NOSHOW: "ノーショー",
  ON_HOLD: "保留",
  CONFIRMED: "確定",
  REQUESTED: "要回答",
  REJECTED: "受入不可",
  CANCELLED: "キャンセル",
} as const;

export default reservationStatusTranslations;
