import { Chip } from "@mui/material";
import { Plan } from "../../List";
import PaidItemList from "./components/PaidItemList";
import { PaidOptionField } from "./types";
import { useFieldArray } from "react-hook-form";
import { v4 as uuidV4 } from "uuid";
import { FormContext } from "../components/FormContext";
import { useContext } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { setArrayItemValue } from "../formValues";

function FaqsEditorForm() {
  const { form } = useContext(FormContext);
  const fieldArray = useFieldArray({
    name: PaidOptionField.plan_paid_items,
    control: form.control,
  });
  const { append, fields } = fieldArray;

  return (
    <>
      <PaidItemList fieldArray={fieldArray} planId={form.planId} />

      <Chip
        style={{ marginTop: "1rem" }}
        label={"有料オプションを追加"}
        icon={<AddCircleIcon />}
        clickable
        onClick={() => {
          append({});
          setArrayItemValue({
            form,
            arrayName: PaidOptionField.plan_paid_items,
            itemIndex: fields.length,
            value: {
              [PaidOptionField.name]: "",
              [PaidOptionField.description]: "",
              [PaidOptionField.price]: "",
              [PaidOptionField.imageUrl]: null,
              [PaidOptionField.status]: 1,
              [PaidOptionField.id]: uuidV4(),
              [PaidOptionField.isDeleted]: false,
            },
          });
        }}
      />
    </>
  );
}

function getDefaultValuesPaidOption(plan: Plan) {
  const planPaidItems = [...plan.publishedPlan.json.paidOptionItems].map((faq) => ({
    ...faq,
    [PaidOptionField.isDeleted]: false,
    [PaidOptionField.id]: faq.id,
  }));
  return {
    [PaidOptionField.plan_paid_items]: planPaidItems,
  };
}

const faqExport = {
  form: FaqsEditorForm,
  getDefaultValues: getDefaultValuesPaidOption,
};
export default faqExport;
