const reservationStatusColors = {
  NOT_YET_RESERVED: "red",
  RESERVED: "green",
  REJECTED: "black",
  CANCELLED: "black",
} as const;

export const bookingStatusColors = {
  NOSHOW: "red",
  ON_HOLD: "gold",
  CONFIRMED: "green",
  REQUESTED: "red",
  REJECTED: "black",
  CANCELLED: "black",
} as const;

export default reservationStatusColors;
