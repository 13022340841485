import { useContext, useState, useCallback } from "react";
import {
  FieldValues,
  UseFieldArrayReturn,
  useFieldArray,
} from "react-hook-form";
import { ActivitiesField } from "../types";
import { FormContext } from "../../components/FormContext";
import { getArrayFieldValue, setArrayFieldValue } from "../../formValues";
import CoursePhotoCard from "./CoursePhotoCard";
import AddCoursePhotoButton from "./AddCoursePhotoButton";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

export default function CoursePhotoList({
  index,
  planId,
}: CoursePhotoListProps) {
  const { form } = useContext(FormContext);
  const fieldArray = useFieldArray({
    name: `${ActivitiesField.Course}.${index}.${ActivitiesField.CoursePhotos}`,
    control: form.control,
  });

  const { append, fields, remove, replace } = fieldArray;
  const [photoFields, setPhotoFields] = useState<any[]>(fields);

  console.log('rebuild')

  const removeItem = (itemIndex: number) => {
    form.addRemovedItem({
      fieldName: `${ActivitiesField.Course}.${index}.${ActivitiesField.CoursePhotos}`,
      fieldId: getArrayFieldValue({
        form,
        arrayName: `${ActivitiesField.Course}.${index}.${ActivitiesField.CoursePhotos}`,
        itemIndex,
        fieldName: `${ActivitiesField.Course}.${index}.${ActivitiesField.CoursePhotoId}`,
      }),
    });
    remove(itemIndex);
  };

  const handleMove = useCallback(
    (sourceImg: string, targetImg: string) => {
      setPhotoFields((prevPhotoFields) => {
        const sourceIndex = prevPhotoFields.findIndex(
          (i) => i.src === sourceImg
        );
        const targetIndex = prevPhotoFields.findIndex(
          (i) => i.src === targetImg
        );

        if (
          sourceIndex === -1 ||
          targetIndex === -1 ||
          sourceIndex === targetIndex
        )
          return prevPhotoFields;

        let newFields;
        if (sourceIndex > targetIndex) {
          newFields = [
            ...prevPhotoFields.slice(0, targetIndex),
            prevPhotoFields[sourceIndex],
            prevPhotoFields[targetIndex],
            ...prevPhotoFields
              .slice(targetIndex + 1)
              .filter((i) => i.src !== sourceImg),
          ];
        } else {
          newFields = [
            ...prevPhotoFields
              .slice(0, targetIndex)
              .filter((i) => i.src !== sourceImg),
            prevPhotoFields[targetIndex],
            prevPhotoFields[sourceIndex],
            ...prevPhotoFields.slice(targetIndex + 1),
          ];
        }
        const updatedFields = newFields.map((photo, i) => {
          return { ...photo, photoIndex: i };
        });

        fields.map((field: any, i: number) => {
          setArrayFieldValue({
            form,
            arrayName: `${ActivitiesField.Course}.${index}.${ActivitiesField.CoursePhotos}`,
            itemIndex: i,
            fieldName: ActivitiesField.CoursePhotoIndex,
            value: updatedFields.findIndex((item) => item.src === field.src),
          });
        });

        replace(updatedFields); // Đồng bộ với react-hook-form
        return updatedFields;
      });
    },
    [replace]
  );

  const renderImage = useCallback(
    (photo: { id: any; src: string }, i: number) => {
      return (
        <CoursePhotoCard
          index={index}
          planId={planId}
          remove={() => removeItem(i)}
          key={photo.id}
          photoId={photo.id}
          itemIndex={i}
          setImageUrl={(imageUrl: string | null) => {
            setArrayFieldValue({
              form,
              arrayName: `${ActivitiesField.Course}.${index}.${ActivitiesField.CoursePhotos}`,
              itemIndex: i,
              fieldName: ActivitiesField.CoursePhotoSrc,
              value: imageUrl,
            });
          }}
          formIdentifier={`${ActivitiesField.Course}.${index}.${ActivitiesField.CoursePhotos}.${i}`}
          setDeleted={(isDeleted: boolean) =>
            setArrayFieldValue({
              form,
              arrayName: `${ActivitiesField.Course}.${index}.${ActivitiesField.CoursePhotos}`,
              itemIndex: i,
              fieldName: ActivitiesField.CoursePhotoIsDeleted,
              value: isDeleted,
            })
          }
          moveFunction={handleMove}
        />
      );
    },
    []
  );

  return (
    <>
      <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
        <DndProvider backend={HTML5Backend}>
          {photoFields.map((photo: any, i) => {
            return renderImage(photo, i);
          })}
        </DndProvider>
      </div>
      <AddCoursePhotoButton
        append={append}
        fields={fields}
        form={form}
        index={index}
      />
    </>
  );
}

interface CoursePhotoListProps {
  planId: string;
  index: number;
}

export const generatePhotoUrl = (planId: string, photoId: string) =>
  `plans/${planId}/course_photos/${photoId}`;
