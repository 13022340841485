import * as React from "react";
import Button from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { useQuery, gql } from "@apollo/client";

type PlanImageDialogProps = Pick<DialogProps, "open"> & {
  handleClose: () => void;
  onConfirm:
    | ((image: string | null) => void)
    | ((image: string | null) => Promise<void>);
  planId: string;
  imageUrl: string | null;
};

export enum PhotoCategory {
  "内観/外観",
  "座席",
  "料理",
  "特典",
  "サービス",
}

const PhotoCategories: PhotoCategory[] = Object.values(PhotoCategory).filter(
  (value): value is PhotoCategory => typeof value === "string"
);

function imageResolution(
  url: string
): Promise<{ width: number; height: number }> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = url;

    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };

    img.onerror = () => {
      reject(new Error("Failed to load image"));
    };
  });
}

export default function PlanImageDialog({
  open,
  handleClose,
  onConfirm,
  planId,
  imageUrl,
}: PlanImageDialogProps) {
  const { loading, error, data } = useQuery(PLAN_IMAGES_QUERY, {
    variables: { planId },
    fetchPolicy: "network-only",
  });
  const planImages: PlanImageType[] = data?.planImages ?? [];

  const [selectImage, setSelectImage] = React.useState<string | null>(imageUrl);

  return (
    <Dialog maxWidth="xl" open={open} onClose={handleClose}>
      <DialogTitle>このプランにアップロードされた画像一覧 </DialogTitle>
      <DialogContent sx={{ width: "1000px" }}>
        {error ? (
          "Image not found!"
        ) : loading ? (
          <CircularProgress />
        ) : (
          planImages.map((planImage: PlanImageType) => {
            return (
              <div style={{ marginBottom: 20 }}>
                {planImage.photos.length > 0 && (
                  <Typography variant="h6">{planImage.category}</Typography>
                )}
                <Grid container spacing={1}>
                  {planImage.photos.map(
                    (photo: { imageUrl: string; category: string }) => {
                      return (
                        <Grid item lg={3}>
                          <div
                            style={{
                              cursor: "pointer",
                              overflow: "hidden",
                              border: "3px solid #fff",
                              outline:
                                photo.imageUrl === selectImage
                                  ? "3px solid #ff0f0f"
                                  : "none",
                            }}
                            onClick={() => setSelectImage(photo.imageUrl)}
                          >
                            <ImageCard
                              imageLink={photo.imageUrl}
                              category={photo.category}
                            />
                          </div>
                        </Grid>
                      );
                    }
                  )}
                </Grid>
              </div>
            );
          })
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            onConfirm(selectImage);
            handleClose();
          }}
        >
          submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function ImageCard({
  imageLink,
  category,
}: {
  imageLink: string;
  category: string;
}) {
  const [resolution, setImageResolution] = React.useState<any>({});

  React.useEffect(() => {
    const fetchImageResolutions = async () => {
      try {
        const resolutions: any = {};

        const { width, height } = await imageResolution(imageLink);
        resolutions.width = width;
        resolutions.height = height;
        setImageResolution(resolutions);
      } catch (error) {
        console.log(error);
      }
    };

    fetchImageResolutions();
  }, [imageLink]);

  return (
    <>
      <img
        style={{
          width: "100%",
          minHeight: "100%",
          height: "165px",
        }}
        src={imageLink}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: category ? "space-between" : "center",
        }}
      >
        {category ? (
          <Select value={category} label="Category" disabled>
            {PhotoCategories.map((v, k) => (
              <MenuItem value={v} key={k}>
                {v}
              </MenuItem>
            ))}
          </Select>
        ) : null}
        <Box>
          <div style={{ textAlign: "center", color: "red" }}>
            <Typography variant="subtitle1">{`${
              resolution?.width ? resolution?.width : "..."
            } x ${
              resolution?.height ? resolution?.height : "..."
            }`}</Typography>
          </div>
          {resolution?.width + resolution?.height <= 1000 ? (
            <div style={{ textAlign: "center", color: "red" }}>
              <Typography variant="subtitle1">低解像度注意</Typography>
            </div>
          ) : (
            ""
          )}
        </Box>
      </Box>
    </>
  );
}

const PLAN_IMAGES_QUERY = gql`
  query PlanIamgesQuery($planId: ID!) {
    planImages(planId: $planId) {
      category
      photos {
        imageUrl
        category
      }
    }
  }
`;

export type PlanImageType = {
  category: string;
  photos: {
    imageUrl: string;
    category: string;
  }[];
};
