import { Button, TextField } from "@mui/material";
import { default as MuiModal } from "@mui/material/Modal";
import Box from "@mui/system/Box";
import { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { useNotifications } from "../../../../components/Notification";

export default function Modal(props: ModalProps) {
  const { showNotification } = useNotifications();
  const [onHoldReason, setOnHoldReason] = useState(props.reason);
  const [editStatusBooking] = useMutation(EDIT_BOOKING_STATUS_MUTATION);
  const handleSubmit = async () => {
    if (!onHoldReason || onHoldReason.trim() === "") {
      showNotification({
        message: "内容を入力してください",
        severity: "error",
      });
      return;
    }
    await editStatusBooking({
      variables: { bookingId: props.bookingId, onHoldReason },
    });
    await props.setValue(false);
    await props.refetch();
    showNotification({
      message: "編集内容が保存されました",
      severity: "success",
    });
  };
  return (
    <MuiModal
      open={props.value}
      onClose={() => props.setValue(false)}
      disableAutoFocus={true}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          backgroundColor: "rgb(255, 255, 255, 1)",
          borderRadius: "5px",
          border: "1px solid black",
          p: 4,
          textAlign: "center",
        }}
      >
        {props.value}
        <Box component="section" sx={{ textAlign: "left", paddingBottom: 2 }}>
          ご対応状況をご教示ください。
        </Box>
        <TextField
          className="login-field"
          variant="outlined"
          value={onHoldReason}
          name="onHoldReason"
          fullWidth
          multiline
          rows={4}
          onChange={(event: { target: { value: string } }) =>
            setOnHoldReason(event.target.value)
          }
        />
        <Box
          component="section"
          sx={{ display: "flex", justifyContent: "end", paddingTop: 2 }}
        >
          <Button
            style={{ backgroundColor: "#c8a063", color: "#ffffff" }}
            type="submit"
            variant="contained"
            onClick={handleSubmit}
          >
            保存
          </Button>
        </Box>
      </Box>
    </MuiModal>
  );
}

const EDIT_BOOKING_STATUS_MUTATION = gql`
  mutation EditBookingStatusMutation($bookingId: ID!, $onHoldReason: String!) {
    editBookingStatus(
      input: { bookingId: $bookingId, onHoldReason: $onHoldReason }
    ) {
      success
    }
  }
`;

interface ModalProps {
  reason: string | "";
  bookingId: string;
  value: boolean;
  setValue: (value: boolean) => void;
  refetch: () => void;
}
